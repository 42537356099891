import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Surface, Txt, Button } from '@vst/beam';
import { OpenInNew } from '@vst/beam-icons/icons';

import { Storage, useScreenResolution } from '@mfe/shared/util';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import { selectConfig } from '@mfe/shared/redux/config';
import useNavigate from '@mfe/services/navigation';
import {
  ActiveAddonsError,
  useShowAddonCustomerSupportMessage,
} from '@mfe/shared/components';

import { CustomerSupportMessage } from './customer-support-message';
import { NoAddons } from './no-add-ons';
import styles from './styles.module.scss';
import { AddOnCard, useHasAddOns } from '@mfe/shared/components';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';

export const OverviewActiveAddons = (): JSX.Element | null => {
  const { t } = useTranslation(['Overview', 'Global']);
  const {
    addOnsPricesAndDiscounts,
    loading: areAddOnsLoading,
    addOnsPricesAndDiscountsLoading,
    error,
  } = useSelector(selectAddOns);

  const { loading: userLoading } = useSelector(selectUser);
  const { showAddonsShop } = useSelector(selectConfig);
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;
  const { hasAddOns, numberOfAddons } = useHasAddOns();
  const showAddonCustomerSupportMessage = useShowAddonCustomerSupportMessage();

  const containerClass = `${styles['addons-container']} ${
    numberOfAddons > 1 ? '' : styles['single-column']
  }`;

  if (!showAddonsShop) return null;
  const isPageLoading =
    addOnsPricesAndDiscountsLoading || userLoading || areAddOnsLoading;

  if (isPageLoading) return <Loading />;

  if (error) {
    return (
      <ActiveAddonsError
        title={t('Profile:addOns.title')}
        description={t('Profile:addOns.error.description')}
        refreshButtonLabel={t('Profile:addOns.error.refreshButton')}
      />
    );
  }

  return (
    <Surface data-cy="addons-container" className={styles['container']}>
      <div className={styles['header']}>
        <Txt variant="bodyLargeBold">{t('Overview:addOns.title')}</Txt>
        {!isMobile && (
          <div className={styles['header-buttons']}>
            <MyAddonsButtons />
          </div>
        )}
      </div>

      {hasAddOns ? (
        <>
          <Surface className={containerClass}>
            {addOnsPricesAndDiscounts.map((addOnOffer, index) => (
              <AddOnCard
                key={index}
                addOnName={addOnOffer.addOnName}
                displaySimplifiedCard={true}
                isVoiceAddon={addOnOffer.addOnName === 'Viasat Voice'}
              />
            ))}
          </Surface>
          {isMobile && (
            <div className={styles['header-buttons']}>
              <MyAddonsButtons />
            </div>
          )}
          {showAddonCustomerSupportMessage && <CustomerSupportMessage />}
        </>
      ) : (
        <NoAddons />
      )}
    </Surface>
  );
};

const MyAddonsButtons = () => {
  const { hasAddOns } = useHasAddOns();
  const { showAddonsShop } = useSelector(selectConfig);

  if (showAddonsShop) {
    return <MyAddonsButtonsNew />;
  }

  if (!hasAddOns) {
    return null;
  }

  return <MyAddonsButtonsOld />;
};

const MyAddonsButtonsOld = () => {
  const { t } = useTranslation(['Overview', 'Global']);
  const { goTo } = useNavigate();

  return (
    <Button
      variant="secondary"
      icon={OpenInNew}
      iconPos="right"
      onClick={(e) => {
        e.preventDefault();
        Storage.setItem('showInternetPlan', true);
        goTo('Profile');
      }}
    >
      {t('Overview:addOns.button')}
    </Button>
  );
};

const MyAddonsButtonsNew = () => {
  const { t } = useTranslation(['Overview', 'Global']);
  const { goTo } = useNavigate();
  const { hasAddOns } = useHasAddOns();

  if (!hasAddOns) return null;

  return (
    <>
      <Button
        variant="tertiary"
        onClick={(e) => {
          e.preventDefault();
          Storage.setItem('showInternetPlan', true);
          goTo('Profile');
        }}
      >
        {t('Overview:addOns.manageAddonsButtonLabel')}
      </Button>

      <Button
        variant="secondary"
        onClick={(e) => {
          e.preventDefault();
          goTo('ShopAddons');
        }}
      >
        {t('Overview:addOns.shopAddonsButtonLabel')}
      </Button>
    </>
  );
};

const Loading = () => {
  return (
    <Surface data-cy="loading" className={styles['loading-container']}>
      <div className={styles['loading-title']} />
      <div className={styles['loading-addon-section']}>
        <div className={styles['icon']} />
        <div className={styles['name']} />
        <div className={styles['desc']} />
      </div>
      <div className={styles['loading-footer']} />
    </Surface>
  );
};
