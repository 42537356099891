import {
  TextField,
  validators,
  Surface,
  Checkbox,
  Tooltip,
  Icon,
  Form,
  Button,
  Txt,
} from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FC, FormEvent, useEffect, useState } from 'react';
import { ArrowDownward, Info } from '@vst/beam-icons/icons';

import {
  selectAddOns,
  setVoiceFormValues,
} from '@mfe/to-be-migrated/redux/addOns';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

import { Step } from './step';
import { StepInstanceProps } from './types';
import { TransitionWrapper } from './transition-wrapper';
import styles from './styles.module.scss';

export const Step3: FC<StepInstanceProps> = ({
  isOpen,
  openStep,
  setOpenStep,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ShopAddons');
  const [isValid, setIsValid] = useState(false);

  const {
    voiceConfig: { callerId, blockCallerId },
  } = useSelector(selectAddOns);

  const {
    userInfo: { firstName, lastName },
  } = useSelector(selectUserInfo);

  const defaultCallerId = `${firstName.charAt(0)} ${lastName}`;

  useEffect(() => {
    if (!callerId) {
      dispatch(setVoiceFormValues({ callerId: defaultCallerId }));
    }
  }, [dispatch]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (callerId && callerId.length <= 15) {
      setIsValid(true);
      setOpenStep(openStep + 1);
    }
  };

  const handleCallerIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    dispatch(setVoiceFormValues({ callerId: text }));
  };

  const handleCheckboxChange = () => {
    dispatch(setVoiceFormValues({ blockCallerId: !blockCallerId }));
  };

  return (
    <Step
      step={3}
      title={t('voice.callerId.title')}
      isValid={isValid}
      handleEdit={() => setOpenStep(3)}
    >
      <TransitionWrapper isOpen={isOpen}>
        {isOpen && (
          <Form
            initialValues={{
              callerId: callerId || defaultCallerId,
              blockCallerId,
            }}
            onSubmit={handleSubmit}
            validationMode="onSubmit"
            className={styles.formContent}
          >
            <Txt variant={'bodySmallRegular'} pb={'24px'}>
              {t('voice.callerId.description')}
            </Txt>
            <TextField
              required
              id="callerId"
              name="callerId"
              title={t('voice.callerId.label')}
              value={callerId}
              labelProps={{ labelText: t('voice.callerId.label') }}
              helperTextProps={{
                showIcon: false,
                helpMessage: t('voice.callerId.helperText'),
              }}
              validationRules={[
                validators.required({
                  message: t('voice.validation.required'),
                }),
                validators.pattern({
                  pattern: /^(?!\s+$).*/,
                  message: t('voice.validation.callerIdEmpty'),
                }),
                validators.maxLength({
                  length: 15,
                  message: t('voice.validation.callerId'),
                }),
              ]}
              onChange={handleCallerIdChange}
            />
            <Surface
              pt={'24px'}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Checkbox
                id="terms"
                checked={blockCallerId}
                onChange={handleCheckboxChange}
                label={t('voice.callerId.block')}
              />
              <Tooltip
                variant="simple"
                style={{ textAlign: 'left' }}
                bodyText={t('voice.callerId.tooltip')}
              >
                <Icon icon={Info} size="16px" color="gray_600" ml="4px" />
              </Tooltip>
            </Surface>
            <Button
              type="submit"
              variant={'secondary'}
              icon={ArrowDownward}
              iconPos={'right'}
              className={styles.nextButton}
            >
              {t('voice.nextStep')}
            </Button>
          </Form>
        )}
      </TransitionWrapper>
    </Step>
  );
};
