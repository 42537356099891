import { trackBillingEvent } from '@mfe/to-be-migrated/redux/analytics';
import {
  PaymentMethodType,
  PaymentsState,
  retrieveTransactionId,
  setPayments,
} from '@mfe/to-be-migrated/redux/payments';
import { VppError } from '@mfe/shared/schema-types';
import { TFunction } from 'i18next';
import React, { Dispatch } from 'react';
import { scrollToTop } from '@mfe/shared/redux/utils';
import { ANALYTICS_EVENT_NAME } from '../shared/constants';
import { modifyTransaction } from '@mfe/to-be-migrated/redux/payments';
import { AUTOPAY_PREAUTH_AMOUNT } from '@mfe/shared/util';

export enum VppEventCode {
  INIT_RECEIVED = 'INIT_RECEIVED',
  WINDOW_RESIZE = 'EVENT_WINDOW_RESIZE',
  TYPE_DETERMINED = 'CARD_TYPE_DETERMINED',
  PAYMENT_TYPE_CHANGED = 'PMT_INPUT_CHANGED_EVENT',
  SAVE_PAYMENT_METHOD_CHANGED = 'SAVE_PAYMENT_METHOD_CHANGED',
  PAYPAL_OPEN = 'PAYPAL_OPEN',
  PAYPAL_CLOSE = 'PAYPAL_CLOSE',
  PAYPAL_ERROR = 'PAYPAL_ERROR',
  CANCEL_BUTTON_CLICKED = 'CANCEL_BUTTON_CLICKED',
  PAY_BUTTON_CLICKED = 'PAY_BUTTON_CLICKED',
  UPDATE_PMT_MTHD_CLICKED = 'UPDATE_PMT_MTHD_CLICKED',
}

type VpsContext = PaymentsState & {
  onCancel: () => void;
  onSuccess: () => void;
  onError: (error: VppError) => void;
};

export const NO_INTERNET_CONNECTION = 'No internet connection';

type VppEventData = {
  //submit response
  success?: 'true' | 'false';
  error?: any;
  // event
  eventCode?: VppEventCode;
  eventMessage?: string;
  // shared
  additionalDetails: any;
};

export const formatCurrency = (value?: number | null, currency?: string) => {
  if (value === undefined || value === null || !currency) return String(value);

  let locale;
  switch (currency) {
    case 'EUR':
      locale = 'it-IT';
      break;
    case 'BRL':
      locale = 'pt-BR';
      break;
    default:
      locale = 'en-US';
      break;
  }
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

  return formatter.format(value);
};

export const postMessageToVPS = (
  VPSData: string,
  url?: string,
  _?: React.RefObject<any>
) => {
  const contentWindow = (
    document.getElementById('vps-iframe') as HTMLIFrameElement | null
  )?.contentWindow;
  url && contentWindow?.postMessage?.(VPSData, url);
};

export const iframeProps = {
  width: '100%',
  frameBorder: '0',
  id: 'vps-iframe',
  scrolling: 'no',
};

const isVppEvent = (event: MessageEvent, vppUrl: string) =>
  vppUrl.startsWith(event.origin);

export const parseVppEvent = (
  event: MessageEvent,
  vppUrl: string
): VppEventData | undefined => {
  if (!isVppEvent(event, vppUrl)) return undefined;

  try {
    return JSON.parse(event.data);
  } catch {
    return undefined;
  }
};

export const handleCommonVppMessages = (
  event: MessageEvent,
  context: VpsContext,
  vppUrl: string | null | undefined,
  dispatch: Dispatch<any>,
  isOffline?: boolean
) => {
  if (!vppUrl) return;
  if (!isVppEvent(event, vppUrl)) return;
  const eventData = parseVppEvent(event, vppUrl);
  if (!eventData) return;

  switch (eventData.eventCode) {
    case VppEventCode.INIT_RECEIVED:
      postMessageToVPS(
        JSON.stringify({ selectedPaymentType: context.paymentMethodType }),
        context.url
      );
      return dispatch(setPayments({ frameReady: true }));

    case VppEventCode.WINDOW_RESIZE:
      try {
        const heightpx =
          String(eventData?.additionalDetails?.iframeHeight) ?? '0px';
        const height = Number(heightpx.replace('px', ''));
        if (height) dispatch(setPayments({ height }));
      } catch {
        // ignore
      }
      return;

    case VppEventCode.SAVE_PAYMENT_METHOD_CHANGED:
      return dispatch(
        setPayments({ saveOTP: eventData.eventMessage?.includes('true') })
      );

    // paypal
    case VppEventCode.PAYPAL_OPEN:
      return dispatch(setPayments({ paypalOpen: true }));
    case VppEventCode.PAYPAL_CLOSE:
    case VppEventCode.PAYPAL_ERROR:
      return dispatch(setPayments({ paypalOpen: false }));

    // cancel
    case VppEventCode.CANCEL_BUTTON_CLICKED:
      return context.onCancel();

    case undefined: {
      // if success doesn't exist, then this probably isn't really the submit response

      if (isOffline) {
        dispatch(
          setPayments({
            error: { code: '', message: NO_INTERNET_CONNECTION },
            submit: false,
          })
        );
        break;
      }
      if (!eventData.success) break;

      if (
        eventData?.additionalDetails?.paymentMethod ||
        eventData?.additionalDetails?.last4Digits
      ) {
        dispatch(
          setPayments({
            paymentMethod: {
              paymentMethod: eventData.additionalDetails.paymentMethod,
              last4Digits: eventData.additionalDetails.last4Digits,
            },
          })
        );
      }

      if (eventData.success === 'true') {
        context.onSuccess();
      } else if (eventData.success === 'false') {
        context.onError(eventData?.error[0]);
      }

      dispatch(scrollToTop());
      return;
    }
  }
};

export const handleAutopayMessages = (
  event: MessageEvent,
  context: VpsContext,
  vppUrl: string | null | undefined,
  dispatch: Dispatch<any>
) => {
  if (!vppUrl) return;
  if (!isVppEvent(event, vppUrl)) return;

  const eventData = parseVppEvent(event, vppUrl);

  if (eventData?.eventCode === VppEventCode.PAYMENT_TYPE_CHANGED) {
    if (eventData.eventMessage?.toLowerCase().includes('ach')) {
      dispatch(setPayments({ paymentMethodType: PaymentMethodType.BANK }));
      if (context.paymentMethodType !== PaymentMethodType.BANK)
        dispatch(modifyTransaction({ amount: 0 }));
    } else if (eventData.eventMessage?.toLowerCase().includes('paypal')) {
      dispatch(setPayments({ paymentMethodType: PaymentMethodType.PAYPAL }));
      if (context.paymentMethodType !== PaymentMethodType.PAYPAL)
        dispatch(modifyTransaction({ amount: 0 }));
    } else {
      dispatch(setPayments({ paymentMethodType: PaymentMethodType.CARD }));
      if (context.paymentMethodType !== PaymentMethodType.CARD)
        dispatch(modifyTransaction({ amount: AUTOPAY_PREAUTH_AMOUNT }));
    }
    return;
  }

  if (eventData?.eventCode === VppEventCode.UPDATE_PMT_MTHD_CLICKED) {
    return dispatch(
      trackBillingEvent({
        eventName: ANALYTICS_EVENT_NAME.updatePaymentMethodSubmitted,
        version: '1-0-0',
        data: {
          submission_method: 'submit_button',
          submission_zone: 'update_payment_screen',
        },
      })
    );
  }
};

export const handleOtpMessages = (
  event: MessageEvent,
  context: VpsContext,
  vppUrl: string | null | undefined,
  dispatch: Dispatch<any>
) => {
  if (!vppUrl) return;
  if (!isVppEvent(event, vppUrl)) return;
  const eventData = parseVppEvent(event, vppUrl);

  if (eventData?.eventCode === VppEventCode.PAYMENT_TYPE_CHANGED) {
    if (eventData.eventMessage?.toLowerCase().includes('ach')) {
      dispatch(setPayments({ paymentMethodType: PaymentMethodType.BANK }));
      if (context.paymentMethodType !== PaymentMethodType.BANK)
        dispatch(retrieveTransactionId(undefined));
    } else if (eventData.eventMessage?.toLowerCase().includes('paypal')) {
      dispatch(setPayments({ paymentMethodType: PaymentMethodType.PAYPAL }));
      if (context.paymentMethodType !== PaymentMethodType.PAYPAL)
        dispatch(retrieveTransactionId(undefined));
    } else {
      dispatch(setPayments({ paymentMethodType: PaymentMethodType.CARD }));
      if (context.paymentMethodType !== PaymentMethodType.CARD)
        dispatch(retrieveTransactionId(undefined));
    }
    return;
  }

  if (eventData?.eventCode === VppEventCode.PAY_BUTTON_CLICKED) {
    return dispatch(
      trackBillingEvent({
        eventName: ANALYTICS_EVENT_NAME.makePaymentSubmitted,
        version: '1-0-0',
        data: {
          submission_method: 'submit_button',
          submission_zone: 'make_a_payment_screen',
        },
      })
    );
  }
};

const GET_TRANSACTION_ID_ERROR_CODE = '[getTransactionId] Failed to fetch';
const GET_TRANSACTION_ID_ERROR_MESSAGE = 'Failed to fetch transaction ID';

export const getOtpPageAlertTitle = (
  error: VppError | undefined,
  t: TFunction
) =>
  error?.code === GET_TRANSACTION_ID_ERROR_CODE
    ? t('VPP:error.getTransactionId.code')
    : t('VPP:error.makePayment.code');

export const getAutopayPageAlertTitle = (
  error: VppError | undefined,
  t: TFunction
) =>
  error?.code === GET_TRANSACTION_ID_ERROR_CODE
    ? t('VPP:error.getTransactionId.code')
    : t('VPP:error.updatePayment.code');

export const getPageAlertDescription = (
  error: VppError | undefined,
  t: TFunction
) =>
  error?.message === GET_TRANSACTION_ID_ERROR_MESSAGE
    ? t('VPP:error.getTransactionId.message')
    : (error?.message as string);
