import React from 'react';
import { getNavItems } from '../shared/utils';
import { ProfileTab } from '@mfe/to-be-migrated/shared-platform-react/pages/Profile';
import styles from './NavBarDropdown.module.scss';
import { Icon, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { Locale } from '@mfe/shared/schema-types';
import { selectConfig } from '@mfe/shared/redux/config';

interface ExtendedDropdownItemsProps {
  currentTab: string;
  accountType: string;
  onClickSwitch: (id: ProfileTab) => void;
}

export const ExtendedDropdownItems = ({
  currentTab,
  accountType,
  onClickSwitch,
}: ExtendedDropdownItemsProps) => {
  const { t } = useTranslation('NewProfile');

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const isEnUs = userLocale === Locale.EnUs;

  const { showAddonsShop } = useSelector(selectConfig);

  const { extendedItems } = getNavItems(
    currentTab,
    accountType,
    t,
    showAddonsShop
  );

  return (
    <>
      {extendedItems.map((navItem) =>
        !isEnUs && navItem.id === ProfileTab.InternetPlan ? null : (
          <button
            className={styles['extendedContainer']}
            onClick={() => onClickSwitch(navItem.id as ProfileTab)}
            key={navItem.id}
          >
            <Icon icon={navItem.icon} color="gray_600" />
            <Txt variant="bodyLargeRegular" ml="8px" color="subtle">
              {navItem.label}
            </Txt>
          </button>
        )
      )}
    </>
  );
};
