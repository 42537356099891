import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InlineLink, StandardModal, Surface, Txt } from '@vst/beam';

interface TermsAndConditionsProps {
  content: string;
}

export const TermsAndConditions: FC<TermsAndConditionsProps> = ({
  content,
}) => {
  const { t } = useTranslation('ShopAddons');
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Surface>
      <Txt variant={'smallRegular'} type={'span'} color={'subtle'}>
        {t('cart.termsAndConditions.beforeLink')}{' '}
        <InlineLink variant="primary" href="#" onClick={handleLinkClick}>
          {t('cart.termsAndConditions.link')}
        </InlineLink>
        {t('cart.termsAndConditions.afterLink')}
      </Txt>

      {isOpen && (
        <StandardModal
          header={t('voice.termsAndConditions')}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          actions={{
            primary: {
              label: t('voice.close'),
              onClick: () => setIsOpen(false),
            },
          }}
          size={'medium'}
        >
          <Txt variant={'tinyRegular'} component={'span'}>
            {content}
          </Txt>
        </StandardModal>
      )}
    </Surface>
  );
};
