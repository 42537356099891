import { ModalTypeEnum } from '@mfe/shared/components';
import { useTranslation } from 'react-i18next';
import styles from './NavBar.module.scss';
import { ProfileTab } from '../../../../index';
import { useSelector } from 'react-redux';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { Locale } from '@mfe/shared/schema-types';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { NavBarItem } from './NavBarItem';
import { getNavItems } from '../shared/utils';
import { NavBarProps } from '../shared/types';
import { selectConfig } from '@mfe/shared/redux/config';

const NavBar = ({
  currentTab,
  setCurrentTab,
  restrictSwitchTabs,
  setShowModal,
  setPendingTab,
}: NavBarProps): JSX.Element => {
  const { t } = useTranslation('NewProfile');

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const { showAddonsShop } = useSelector(selectConfig);

  const { navBarItems } = getNavItems(
    currentTab,
    accountType,
    t,
    showAddonsShop
  );

  const onClick = (navItemId: ProfileTab) => {
    if (!restrictSwitchTabs) setCurrentTab(navItemId);
    else {
      setPendingTab(navItemId);
      setShowModal(ModalTypeEnum.Unsaved);
    }
  };

  return (
    <div className={styles['buttonContainerMargin']}>
      <div data-cy="profile-nav-bar" className={styles['buttonContainer']}>
        {navBarItems.map((navItem) => (
          <NavBarItem
            key={navItem.id}
            id={navItem.id}
            icon={navItem.icon}
            isActiveTab={navItem.id === currentTab}
            onClick={() => onClick(navItem.id as ProfileTab)}
            label={navItem.label}
          />
        ))}
      </div>
      <hr />
    </div>
  );
};

export default NavBar;
