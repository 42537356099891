import { MVEnv } from '@mfe/services/env-files';

const env: MVEnv = {
  env: 'PREPROD',
  myViasatUrl: 'https://my-viasat-preprod.icat.viasat.io',
  debug: {
    auth: true,
    dev: false,
    showAnalytics: false,
    skipLogin: false,
  },
  microfrontends: {
    tsUsage: {
      url: 'https://my-viasat.ts-usage.preprod.icat.viasat.io/Usage',
    },
    manageBilling: {
      url: 'https://my-viasat.ts-usage.preprod.icat.viasat.io/ManageBilling',
    },
    overview: {
      url: 'https://my-viasat.ts-usage.preprod.icat.viasat.io/Overview',
    },
    auth: {
      url: 'https://my-viasat.ts-usage.preprod.icat.viasat.io/auth',
    },
    profile: {
      url: 'https://my-viasat.ts-usage.preprod.icat.viasat.io/Profile',
    },
  },
  backEndUrls: {
    backEndUrl: 'https://my-viasat-preprod.icat.viasat.io/api/graphql',
    androidBackEndUrl: 'https://my-viasat-preprod.icat.viasat.io/api/graphql',
  },
  pixel: {
    namespace: 'MyViasat-preprod',
    cookieDomain: 'localhost',
  },
};

export default env;
