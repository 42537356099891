import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AddOnType, selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { Txt } from '@vst/beam';
import { AddOnCard } from './AddOnCard';
import styles from './AddOnSection.module.scss';
import { AccountType } from '@mfe/shared/schema-types';

export const AddOnSection = (): JSX.Element => {
  const { t } = useTranslation('Overview');
  const { loading, addOns } = useSelector(selectAddOns);
  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const isSMBAccount = accountType === AccountType.Smb;

  const showVoiceAd = !addOns.hasVoice;
  const showEasyCareAd = !addOns.hasEasyCare && !isSMBAccount;
  const showStaticIPAd = !addOns.hasStaticIP && isSMBAccount;
  let showShieldAd = !addOns.hasShield && !isSMBAccount;
  if (showVoiceAd && showEasyCareAd) {
    showShieldAd = false;
  }

  const getMoreText = t('getMore');
  const getMoreSection = (
    <div className={styles['addOnText']}>
      <Txt variant="heading5" component="span">
        {getMoreText}
      </Txt>
    </div>
  );

  const voiceCard = <AddOnCard loading={loading} type={AddOnType.Voice} />;
  const easyCareCard = (
    <AddOnCard loading={loading} type={AddOnType.EasyCare} />
  );
  const shieldCard = <AddOnCard loading={loading} type={AddOnType.Shield} />;
  const staticIPCard = (
    <AddOnCard loading={loading} type={AddOnType.StaticIP} />
  );

  return (
    <div className={styles['addOnSection']}>
      {getMoreSection}
      <div className={styles['addOnCardList']}>
        {showVoiceAd && voiceCard}
        {showEasyCareAd && easyCareCard}
        {showShieldAd && shieldCard}
        {showStaticIPAd && staticIPCard}
      </div>
    </div>
  );
};

export default AddOnSection;
