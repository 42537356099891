import { ModalTypeEnum } from '@mfe/shared/components';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { Icon, Txt } from '@vst/beam';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProfileTab } from '../../../../index';
import CaretExpandIcon from '../../../../img/CaretExpand';
import { NavBarProps } from '../shared/types';
import styles from './NavBarDropdown.module.scss';
import { getNavItems } from '../shared/utils';
import { ExtendedDropdownItems } from './ExtendedDropdown';
import { selectConfig } from '@mfe/shared/redux/config';

const NavBarDropdown = ({
  currentTab,
  setCurrentTab,
  restrictSwitchTabs,
  setShowModal,
  setPendingTab,
}: NavBarProps): JSX.Element => {
  const { t } = useTranslation('NewProfile');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const { showAddonsShop } = useSelector(selectConfig);

  const onClick = () => {
    setShowDropdown(!showDropdown);
  };

  const onClickSwitch = (tabId: ProfileTab) => {
    if (!restrictSwitchTabs) {
      setCurrentTab(tabId);
    } else {
      setPendingTab(tabId);
      setShowModal(ModalTypeEnum.Unsaved);
    }
    setShowDropdown(false);
  };

  const { selectedItem } = getNavItems(
    currentTab,
    accountType,
    t,
    showAddonsShop
  );

  return (
    <div
      data-cy="profile-nav-dropdown"
      className={
        showDropdown
          ? styles['dropdownContainerOpen']
          : styles['dropdownContainer']
      }
    >
      <button
        className={
          showDropdown ? styles['dropdownMainOpen'] : styles['dropdownMain']
        }
        onClick={onClick}
      >
        <div className={styles['label']}>
          <Icon icon={selectedItem[0]?.icon} color="teal_600" />
          <Txt variant="labelLarge" ml="8px" color="regular">
            {selectedItem[0]?.label}
          </Txt>
        </div>
        <div className={showDropdown ? styles['rotate180'] : styles['none']}>
          <CaretExpandIcon />
        </div>
      </button>
      {showDropdown && (
        <ExtendedDropdownItems
          currentTab={currentTab}
          accountType={accountType}
          onClickSwitch={onClickSwitch}
        />
      )}
    </div>
  );
};

export default NavBarDropdown;
