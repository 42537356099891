import {
  Surface,
  Txt,
  Spacer,
  Checkbox,
  TextField,
  validators,
  ConventionalAutocomplete,
  Button,
  Form,
} from '@vst/beam';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownward } from '@vst/beam-icons/icons';

import {
  selectAddOns,
  setVoiceFormValues,
  setShowModal as setShowModalAction,
  scrubEmergencyAddress,
} from '@mfe/to-be-migrated/redux/addOns';
import {
  ScrubAddressRecommendation,
  ScrubAddressProcessStatus,
} from '@mfe/shared/graphql/PAL/types';
import { Address } from '@mfe/shared/schema-types';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { ModalType, ModalTypeEnum } from '@mfe/shared/components';

import { LoadingE911Modal } from './loading-E911-modal';
import { E911AddressErrorModal } from './E911-address-error-modal';
import { SuggestedE911AddressModal } from './suggested-E911-address-modal';
import { TransitionWrapper } from './transition-wrapper';
import { StepInstanceProps, CountryCode } from './types';
import { AddressInput } from './address-input';
import { STATES } from './constants';
import { Step } from './step';
import styles from './styles.module.scss';

export const Step2: FC<StepInstanceProps> = ({
  isOpen,
  openStep,
  setOpenStep,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ShopAddons');
  const [isValid, setIsValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    voiceConfig: {
      emergencyAddress: {
        streetAddress,
        cityAddress,
        stateProvince,
        zipCode,
        aptUnit,
      },
      useServiceAddressForE911,
    },
    isScrubbingE911Loading,
    showModal,
    scrubbedE911Address,
  } = useSelector(selectAddOns);

  const {
    userInfo: {
      address: { service },
    },
  } = useSelector(selectUserInfo);

  const displayedServiceAddress = createAddress(service, t);

  const scrubbedEmergencyAddress = {
    addressLines: [streetAddress, aptUnit ?? ''],
    municipality: cityAddress,
    region: stateProvince,
    postalCode: zipCode,
    countryCode: CountryCode.US,
  };

  const { processStatus, recommendation } = scrubbedE911Address;

  const incrementStep = () => {
    setIsValid(true);
    setOpenStep(openStep + 1);
  };

  const handleStepUpdate = useCallback(() => {
    if (
      processStatus === ScrubAddressProcessStatus.Verified ||
      (processStatus === ScrubAddressProcessStatus.Corrected &&
        recommendation === ScrubAddressRecommendation.RECOMMEND)
    ) {
      incrementStep();
    }
  }, [processStatus, recommendation]);

  useEffect(() => {
    handleStepUpdate();
  }, [handleStepUpdate, processStatus, recommendation]);

  const saveAddress = () => {
    dispatch(scrubEmergencyAddress(scrubbedEmergencyAddress));
    dispatch(setShowModalAction(ModalTypeEnum.Address));
    setIsModalOpen(true);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (useServiceAddressForE911) {
      incrementStep();
    } else {
      saveAddress();
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setVoiceFormValues({
        useServiceAddressForE911: !!e.currentTarget.checked,
      })
    );
    setIsValid(e.currentTarget.checked);
  };

  const handleEdit = () => {
    setOpenStep(2);
    setIsModalOpen(false);
  };

  return (
    <Step
      step={2}
      title={t('voice.e911.title')}
      isValid={isValid}
      handleEdit={handleEdit}
    >
      <TransitionWrapper isOpen={isOpen}>
        {isOpen && (
          <Form
            initialValues={{
              streetAddress,
              cityAddress,
              stateProvince,
              zipCode,
              aptUnit: aptUnit ?? '',
              useServiceAddressForE911,
            }}
            onSubmit={handleSubmit}
            validationMode="onSubmit"
            className={styles.formContent}
          >
            <Txt variant={'bodySmallRegular'}>
              {t('voice.e911.description')}
            </Txt>
            <Spacer y={'24px'} />
            <Checkbox
              id="terms"
              checked={useServiceAddressForE911}
              onChange={handleCheckboxChange}
              label={t('voice.e911.sameAddress')}
            />
            {useServiceAddressForE911 ? (
              <Txt
                variant="bodySmallRegular"
                pt={'24px'}
                style={{ maxWidth: '200px' }}
              >
                {displayedServiceAddress}
              </Txt>
            ) : (
              <Surface
                variant={'primary'}
                pt={'24px'}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Surface className={styles.formGroup}>
                  <AddressInput
                    fluid
                    required
                    id="streetAddress"
                    name="streetAddress"
                    title={t('voice.e911.streetAddress')}
                    placeholder={t('voice.e911.streetAddress')}
                    labelProps={{
                      labelText: t('voice.e911.streetAddress'),
                    }}
                    value={streetAddress}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const text = event.currentTarget.value;
                      dispatch(
                        setVoiceFormValues({
                          emergencyAddress: { streetAddress: text },
                        })
                      );
                    }}
                    validation={{
                      required: t('voice.validation.required'),
                    }}
                  />
                  <TextField
                    fluid
                    id={'aptAddress'}
                    name={'aptAddress'}
                    title={'Apt/Suite'}
                    placeholder={t('voice.e911.aptUnit')}
                    labelProps={{
                      labelText: `${t('voice.e911.aptUnit')} ${t(
                        'voice.e911.optional'
                      )}`,
                    }}
                    value={aptUnit}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const text = event.currentTarget.value;
                      dispatch(
                        setVoiceFormValues({
                          emergencyAddress: { aptUnit: text },
                        })
                      );
                    }}
                  />
                </Surface>
                <Spacer y={'24px'} />
                <Surface className={styles.formGroup}>
                  <TextField
                    fluid
                    required
                    id="cityAddress"
                    name="cityAddress"
                    title={t('voice.e911.city')}
                    placeholder={t('voice.e911.city')}
                    labelProps={{ labelText: t('voice.e911.city') }}
                    value={cityAddress}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const text = event.currentTarget.value;
                      dispatch(
                        setVoiceFormValues({
                          emergencyAddress: { cityAddress: text },
                        })
                      );
                    }}
                    validationRules={[
                      validators.required({
                        message: t('voice.validation.required'),
                      }),
                    ]}
                  />
                  <ConventionalAutocomplete
                    fluid
                    required
                    id={'stateProvince'}
                    name={'stateProvince'}
                    title={t('voice.e911.state')}
                    placeholder={scrubbedEmergencyAddress.region}
                    labelProps={{
                      labelText: t('voice.e911.stateProvince'),
                    }}
                    value={stateProvince}
                    options={STATES}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const text = event.target as HTMLInputElement;
                      dispatch(
                        setVoiceFormValues({
                          emergencyAddress: { stateProvince: text.value },
                        })
                      );
                    }}
                    validationRules={[
                      validators.required({
                        message: t('voice.validation.required'),
                      }),
                    ]}
                  />
                  <TextField
                    fluid
                    required
                    id="zipCode"
                    name="zipCode"
                    title={t('voice.e911.zip')}
                    placeholder={t('voice.e911.zip')}
                    labelProps={{ labelText: t('voice.e911.zip') }}
                    value={zipCode}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const number = event.currentTarget.value;
                      dispatch(
                        setVoiceFormValues({
                          emergencyAddress: { zipCode: number },
                        })
                      );
                    }}
                    validationRules={[
                      validators.define((value) =>
                        validatePostalCode(Number(value), t)
                      ),
                    ]}
                  />
                </Surface>
                <ScrubAddressModal
                  isScrubbingE911Loading={isScrubbingE911Loading}
                  modalType={showModal}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  scrubbedE911Address={scrubbedE911Address}
                  handleIncrementStep={incrementStep}
                />
              </Surface>
            )}
            <Button
              type="submit"
              variant={'secondary'}
              icon={ArrowDownward}
              iconPos={'right'}
              className={styles.nextButton}
            >
              {t('voice.nextStep')}
            </Button>
          </Form>
        )}
      </TransitionWrapper>
    </Step>
  );
};

function validatePostalCode(value: number, t: TFunction) {
  const postalCode = String(value || '').trim();
  const matchesFormat = /^\d{5}([ -]\d{4})?$/.test(postalCode);
  return postalCode.length === 0 || !matchesFormat
    ? { error: true, message: t('voice.validation.postalCode') }
    : { error: false, message: '' };
}

function createAddress(
  addressInput: Address | undefined | null,
  t: TFunction
): string {
  if (!addressInput || addressInput.addressLines.length === 0) {
    return t('voice.noAddress');
  }
  const [line1, line2] = addressInput.addressLines;

  const line3 = `${addressInput.municipality}, ${addressInput.region} ${addressInput.postalCode}`;

  let parsedString = line1 ?? '';

  if (line2) {
    parsedString += `\n${line2}`;
  }
  parsedString += `\n${line3}`;

  return parsedString;
}

const ScrubAddressModal: FC<{
  isScrubbingE911Loading: boolean;
  modalType: ModalType;
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  scrubbedE911Address: any;
  handleIncrementStep: () => void;
}> = ({
  isScrubbingE911Loading,
  modalType,
  isModalOpen,
  setIsModalOpen,
  scrubbedE911Address,
  handleIncrementStep,
}) => {
  if (isScrubbingE911Loading) {
    return <LoadingE911Modal isOpen={isModalOpen} />;
  }

  switch (modalType) {
    case ModalTypeEnum.Address:
      return (
        <SuggestedE911AddressModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          scrubbedE911Address={scrubbedE911Address}
          incrementStep={handleIncrementStep}
        />
      );
    case ModalTypeEnum.AddressError:
      return (
        <E911AddressErrorModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      );
    default:
      return null;
  }
};
