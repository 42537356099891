import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, StandAloneLink, Surface, Txt } from '@vst/beam';

import { Step1 } from './step-1';
import { Step2 } from './step-2';
import { Step3 } from './step-3';
import { Step4 } from './step-4';
import { TermsAndConditions } from '../components/TermsAndConditions';
import styles from './styles.module.scss';

export const ConfigureVoiceCard = ({
  content,
  handleBack,
  handleSubmit,
  isSubmitDisabled,
}: {
  content: string;
  handleBack: () => void;
  handleSubmit: () => void;
  isSubmitDisabled: boolean;
}) => {
  const { t } = useTranslation('ShopAddons');
  const [openStep, setOpenStep] = useState(1);
  const stepProps = { openStep, setOpenStep };
  return (
    <Surface responsive className={styles.content}>
      <Txt variant={'bodyLargeRegular'}>{t('voice.cardHeader')}</Txt>
      <>
        <Step1 isOpen={openStep === 1} {...stepProps} />
        <Step2 isOpen={openStep === 2} {...stepProps} />
        <Step3 isOpen={openStep === 3} {...stepProps} />
        <Step4 isOpen={openStep === 4} {...stepProps} />
      </>
      <Txt
        pt={'40px'}
        pb={'8px'}
        variant={'smallRegular'}
        color={'subtle'}
        component={'p'}
      >
        [Catalog disclosure]
      </Txt>
      <TermsAndConditions content={content} />
      <Surface>
        <hr className={styles.divider} />
        <Actions
          submit={handleSubmit}
          back={handleBack}
          isSubmitDisabled={isSubmitDisabled}
        />
      </Surface>
    </Surface>
  );
};

const Actions: FC<{
  submit: () => void;
  back: () => void;
  isSubmitDisabled: boolean;
}> = ({ submit, back, isSubmitDisabled }) => {
  const { t } = useTranslation('ShopAddons');

  const handleBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    back();
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    submit();
  };

  return (
    <div className={styles.buttons}>
      <StandAloneLink
        variant="primary"
        linkSize="medium"
        children={t('voice.backToVoiceFeatures')}
        href={'#'}
        data-cy="navigatorBackLink"
        onClick={handleBack}
      />
      <Button
        variant="primary"
        fluid
        type="submit"
        data-cy="navigatorContinueButton"
        onClick={handleSubmit}
        disabled={isSubmitDisabled}
      >
        {t('subscribeNow')}
      </Button>
    </div>
  );
};
