import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@vst/beam';

import {
  selectAddOns,
  setCurrentShopAddonId,
} from '@mfe/to-be-migrated/redux/addOns';
import useNavigate from '@mfe/services/navigation';
import { AccountType, type ProductType } from '@mfe/shared/schema-types';
import { ShopAddonsLink } from '@mfe/legacy/mv/utils/Navigation';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { getCharacteristicValue } from '@mfe/shared/util';

import { AddonsGridErrorState } from './AddonsGridErrorState';
import { AddonsGridItem } from './AddonsGridItem';
import { getPriceAndDiscount, sortProductTypesByDisplayOrder } from './utils';
import { AddonsGridItemType } from './types';
import { AddonsGridEmptyState } from './AddonsGridEmptyState';
import styles from './index.module.scss';

const AddonsGrid = () => {
  const { availableAddons } = useSelector(selectAddOns);
  const { loading: userLoading } = useSelector(selectUser);
  const { disableSmbAddons, disableResidentialAddons } =
    useSelector(selectConfig);
  const { userInfo } = useSelector(selectUserInfo);
  const { t } = useTranslation('ShopAddons');
  const { loading, productTypes } = availableAddons;

  if (loading || userLoading) {
    return (
      <div className={styles['wrapper']}>
        <div className={styles['spinnerWrapper']}>
          <Spinner description={t('loading.description')} />
        </div>
      </div>
    );
  }

  if (!productTypes) {
    return <AddonsGridErrorState />;
  }

  const disabledAddons =
    userInfo.accountType === AccountType.Residential
      ? disableResidentialAddons
      : disableSmbAddons;

  const productTypesSorted = productTypes
    .slice()
    .sort(sortProductTypesByDisplayOrder)
    .filter((productType) => !disabledAddons?.includes(productType.id));

  if (productTypesSorted.length === 0) {
    return <AddonsGridEmptyState />;
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['grid']}>
        {productTypesSorted.map((addon, i) => (
          <Addon key={i} addon={addon} />
        ))}
      </div>
    </div>
  );
};

enum MarketingCharacteristics {
  OFFER_NAME = 'OFFER_NAME',
  OFFER_DESCRIPTION = 'OFFER_DESCRIPTION',
}

const Addon: React.FC<{ addon: ProductType }> = (props) => {
  const { addon } = props;
  const { t } = useTranslation('ShopAddons');
  const dispatch = useDispatch();
  const { goTo } = useNavigate();

  const badgeText = useMemo(() => {
    if (addon.kind === 'bep.ofm.product-types.satellite-internet-add-on') {
      return t('selectAddon.voiceBadgeText');
    }
    return undefined;
  }, [addon.kind, t]);

  const {
    price = undefined,
    oldPrice = undefined,
    promotions = [],
  } = getPriceAndDiscount(addon);

  const passedProps: AddonsGridItemType = {
    kind: addon.kind,
    title:
      getCharacteristicValue(
        addon.marketing_copy.translations[0].characteristics,
        MarketingCharacteristics.OFFER_NAME
      ) ?? addon.name,
    badgeText: badgeText,
    oldPrice: oldPrice,
    price: price,
    promotions: promotions,
    description:
      getCharacteristicValue(
        addon.marketing_copy.translations[0].characteristics,
        MarketingCharacteristics.OFFER_DESCRIPTION
      ) ?? addon.description,
    button: {
      text: t('selectAddon.addToPlanCTALabel'),
      onClick: () => {
        dispatch(setCurrentShopAddonId(addon.id));
        goTo(`${ShopAddonsLink.ShopAddons}/${ShopAddonsLink.Checkout}`);
      },
    },
  };

  return <AddonsGridItem {...passedProps} />;
};

export default AddonsGrid;
