import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isUnleashed } from '@mfe/shared/util';
import { AccountType, Locale } from '@mfe/shared/schema-types';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { selectPlanCharacteristics } from '@mfe/to-be-migrated/redux/plan';
import { selectStreamOnPromo } from '@mfe/to-be-migrated/redux/streamOnPromo';
import {
  ArticleCard,
  useQuestionArticleCardProps,
} from '@mfe/shared/components';

import Illustration1 from './img/Illustration1';
import IllustrationBrazilPix from './img/IllustrationBrazilPix';
import AddOnSection from './components/BodySection/AddOnSection';
import { articleQuestionsBrazil } from './InternationalArticleCards';
import { BrazilServiceCard } from './components/BodySection/BrazilServiceCard';
import { ItalyServiceCard } from './components/BodySection/ItalyServiceCard';

import styles from './Overview.module.scss';
import { selectConfig } from '@mfe/shared/redux/config';

const StreamOnPromo = React.lazy(
  () => import('@mfe/features/stream-on/pages/StreamOnPromo')
);

type ServiceCardLocale = Locale.PtBr | Locale.ItIt;

const useSMBArticle = () => {
  const { t } = useTranslation('Overview');

  return useQuestionArticleCardProps({
    title: t('QuestionSMB.1.title'),
    caption: t('QuestionSMB.1.caption'),
    websiteLink: t('QuestionSMB.1.websiteLink'),
    linkText: t('QuestionSMB.1.linkText'),
  });
};

const useResidentialArticle = () => {
  const { t } = useTranslation('Overview');

  return useQuestionArticleCardProps({
    title: t('Question.1.title'),
    caption: t('Question.1.caption'),
    websiteLink: t('Question.1.link'),
    linkText: t('readArticle'),
  });
};

const useBrArticle = () => {
  const { t } = useTranslation('Overview');

  return useQuestionArticleCardProps({
    title: t(articleQuestionsBrazil[0].title),
    caption: t(articleQuestionsBrazil[0].caption),
  });
};

export const OverviewArticlesSection = () => {
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const {
    isEligible,
    hasStreamOnActive,
    didOrderSucceed: streamOrderSucceeded,
  } = useSelector(selectStreamOnPromo);

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const {
    characteristics: { family },
    loading,
  } = useSelector(selectPlanCharacteristics);

  const { showAddonsShop } = useSelector(selectConfig);

  const showStreamPromo =
    !loading &&
    !hasStreamOnActive &&
    !streamOrderSucceeded &&
    isEligible &&
    !isUnleashed(family);

  const isUS = userLocale === Locale.EnUs;
  const isBrazil = userLocale === Locale.PtBr;
  const isSMB = accountType === AccountType.Smb;

  const serviceCards: Record<ServiceCardLocale, JSX.Element> = {
    [Locale.PtBr]: <BrazilServiceCard />,
    [Locale.ItIt]: <ItalyServiceCard />,
  };

  const smbArticleQuestion = useSMBArticle();
  const residentialArticleQuestion = useResidentialArticle();
  const brArticle = useBrArticle();

  return isUS ? (
    <>
      <div className={styles['articleMargin']}>
        {isSMB ? (
          <ArticleCard {...smbArticleQuestion} image={<Illustration1 />} />
        ) : (
          <ArticleCard
            {...residentialArticleQuestion}
            image={<Illustration1 />}
          />
        )}
      </div>
      {showStreamPromo && (
        <React.Suspense fallback={null}>
          <div className={styles['streamOnMargin']}>
            <StreamOnPromo />
          </div>
        </React.Suspense>
      )}
      {!showAddonsShop && (
        <div className={styles['addOnMargin']}>
          <AddOnSection />
        </div>
      )}
    </>
  ) : (
    <div className={styles['serviceCards']}>
      <div className={styles['serviceCardWrapper']}>
        {serviceCards[userLocale as ServiceCardLocale]}
      </div>
      {isBrazil && (
        <div className={styles['articleMargin']}>
          <ArticleCard {...brArticle} image={<IllustrationBrazilPix />} />
        </div>
      )}
    </div>
  );
};
