import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { setPayments } from '@mfe/to-be-migrated/redux/payments';
import { Invoice, Locale, Money } from '@mfe/shared/schema-types';
import { colors, RadioButton, TextField, Txt } from '@vst/beam';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '../../shared-ui/divider';
import styles from '../vpp.module.scss';
import { formatCurrency } from '../utils';
import { getCurrencySymbol } from '@mfe/shared/util';
import { MethodType } from './payment-method';

export enum AmountType {
  balance = 'balance',
  other = 'other',
}

const getInvoiceAmount = (userLocale: Locale, invoice: Invoice) => {
  if (userLocale === Locale.PtBr)
    return formatCurrency(
      invoice.amountOwedOnInvoice?.value,
      invoice?.amountOwedOnInvoice?.alphabeticCode ?? undefined
    );

  return formatCurrency(
    invoice.invoiceAmount?.value,
    invoice?.invoiceAmount?.alphabeticCode ?? undefined
  );
};

const InvoiceAmount = ({ invoice }: { invoice: Invoice }) => {
  const { t } = useTranslation('VPP');

  const dispatch = useDispatch();
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  useEffect(() => {
    if (invoice?.invoiceAmount?.value) {
      dispatch(setPayments({ txnAmount: invoice?.invoiceAmount?.value }));
    }
  }, [dispatch, invoice]);

  return (
    <RadioButton
      value="balance"
      label={
        <Txt variant="bodySmallRegular">
          {t('invoiceAmount', {
            invoiceNumber: invoice?.invoiceNumber,
            invoiceAmount: getInvoiceAmount(userLocale, invoice),
            statementDate: invoice.statementDate
              ? new Date(invoice.statementDate)
              : new Date(),
          })}
        </Txt>
      }
      checked={true}
      className={styles['radioButton']}
    />
  );
};

const processOtherAmount = (isEU: boolean, amount: string) =>
  isEU ? amount.replace(',', '.') : amount;

const otherAmountInitialValue = (isEU: boolean, balanceValue: number) => {
  if (balanceValue < 6) return isEU ? '5,00' : '5.00';

  return isEU ? `${balanceValue},00` : balanceValue.toFixed(2);
};

type Props = {
  balance?: Money | null;
  invoice?: Invoice | null;
  amountType: AmountType;
  setAmountType: React.Dispatch<React.SetStateAction<AmountType>>;
  setMethodType: React.Dispatch<React.SetStateAction<MethodType>>;
};

const BalanceAmount = ({
  balance,
  amountType,
  setAmountType,
  setMethodType,
}: Props) => {
  const { t } = useTranslation('VPP');
  const dispatch = useDispatch();

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const isEU = userLocale === Locale.ItIt;

  const balanceValue = balance?.value ?? 0;
  const formattedBalance = balance?.text;

  const [otherAmount, setOtherAmount] = useState(() =>
    otherAmountInitialValue(isEU, balanceValue)
  );
  const translationLocale = userLocale === Locale.ItIt ? '_EURO' : '';
  const processedAmount = processOtherAmount(isEU, otherAmount);
  const error =
    Number(processedAmount) < 5 || Number(processedAmount) > 999.99
      ? 'error'
      : undefined;

  useEffect(() => {
    if (amountType === AmountType.balance) {
      dispatch(setPayments({ txnAmount: balanceValue }));
      return;
    }

    dispatch(setPayments({ txnAmount: Number(processedAmount) }));
    if (Number(processedAmount) < 5 || Number(processedAmount) > 999.99) {
      setMethodType(MethodType.pof);
    }
  }, [
    amountType,
    balanceValue,
    dispatch,
    otherAmount,
    setMethodType,
    processedAmount,
  ]);

  const handleChange = (type: AmountType) => () => {
    setAmountType(type);
    dispatch(
      setPayments({
        txnAmount:
          type === AmountType.balance
            ? balanceValue
            : Number(otherAmount.replace(',', '')),
      })
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInput = (event: any) => {
    event?.preventDefault();

    // cursor position
    const caret = event?.target?.selectionStart;
    const element = event?.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = caret;
      element.selectionEnd = caret;
    });

    // ignore backwards / non-numbers
    if (
      event.nativeEvent.inputType === 'deleteContentBackward' &&
      caret === otherAmount.length - 3
    ) {
      return;
    }

    const allowed = '0123456789.';
    if (
      !allowed.includes(event.nativeEvent.data) &&
      event.nativeEvent.data !== null
    ) {
      return;
    }

    // "masking"
    const value = String(event?.target?.value ?? 0).trim();
    const localevalue = !isEU ? value.indexOf('.') : value.indexOf(',');
    const trimmed = value.slice(0, localevalue + 3);
    const parsed = parseFloat(trimmed);
    const fixed = parsed.toFixed(2);
    if (fixed === 'NaN') {
      return setOtherAmount('0.00');
    }

    setOtherAmount(!isEU ? fixed : fixed.replace('.', ','));
  };

  const CurrencyText = (
    <Txt variant="bodySmallRegular" color="regular">
      {getCurrencySymbol(userLocale)}
    </Txt>
  );

  return (
    <>
      {balanceValue > 0 && (
        <RadioButton
          value="balance"
          label={
            <Txt variant="bodySmallRegular">
              {t('balanceAmount', { amount: formattedBalance })}
            </Txt>
          }
          checked={amountType === AmountType.balance}
          onChange={handleChange(AmountType.balance)}
          className={styles['radioButton']}
        />
      )}

      {balanceValue <= 0 && (
        <div className={styles['radioButton']}>
          <Txt variant="bodySmallRegular" color="subtle">
            {t('balanceAmountPaid', { amount: formattedBalance })}
          </Txt>
        </div>
      )}

      <Divider />

      <RadioButton
        data-cy="pay-other-amount-radio-button"
        value="other"
        label={t('payOtherAmount')}
        checked={amountType === AmountType.other}
        onChange={handleChange(AmountType.other)}
        className={styles['radioButton']}
      />
      {amountType === AmountType.other && (
        <div className={styles['textInput']}>
          <TextField
            data-cy="amount-input"
            name="paymentAmount"
            error={error}
            contentLeft={!isEU ? CurrencyText : undefined}
            contentRight={isEU ? CurrencyText : undefined}
            color={colors.gray[800]}
            value={otherAmount}
            style={{ marginLeft: userLocale !== Locale.EnUs ? 8 : 0 }}
            onChange={handleInput}
            inputMode="numeric"
            helperTextSlot={
              <Txt variant="tinyItalic" color={error ? 'error' : 'subtle'}>
                {Number(processedAmount) > 999.99
                  ? t(`paymentAmountMax${translationLocale}`)
                  : t(`paymentAmountTip${translationLocale}`)}
              </Txt>
            }
          />
        </div>
      )}
    </>
  );
};

const PaymentAmount = ({
  balance,
  invoice,
  amountType,
  setAmountType,
  setMethodType,
}: Props) => {
  return (
    <div className={styles['radioGroupContainer']} data-cy="pay-balance">
      {invoice ? (
        <InvoiceAmount invoice={invoice} />
      ) : (
        <BalanceAmount
          balance={balance}
          amountType={amountType}
          setAmountType={setAmountType}
          setMethodType={setMethodType}
        />
      )}
    </div>
  );
};

export default PaymentAmount;
