import { useTranslation } from 'react-i18next';

import { Offer } from '@vst/beam-icons/icons';
import { AddonIcon } from '@mfe/shared/components';
import { Surface, Button, Txt, Icon } from '@vst/beam';

import { CartProps } from './types';
import styles from './styles.module.scss';
import { TermsAndConditions } from '../components';
import { getPriceAndDiscount } from '../addons-grid/utils';
import { getTermsAndConditions } from '../components/utils';

export const AddonCart: React.FC<CartProps> = ({
  addon,
  submitButtonLabel,
  isSubmitDisabled,
  handleClick,
}) => {
  const { t } = useTranslation('ShopAddons');
  const { name, kind } = addon;
  const {
    price = undefined,
    oldPrice = undefined,
    totalPromotionDuration = undefined,
    promotions = [],
  } = getPriceAndDiscount(addon);

  const termsAndConditionsContent = t(
    `termsAndConditions.${getTermsAndConditions(kind)}`
  );

  const hasPromotions = promotions.length > 0;

  return (
    <div className={styles['cart']}>
      <Surface radius="16px" p="24px">
        <div className={styles['vertical-spacing']}>
          <Txt variant="bodyLargeBold" color="subtle">
            {t('cart.title')}
          </Txt>
          <div className={styles['content']}>
            <Addon name={name} kind={kind} />
            <div className={styles['subscription-price']}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Txt variant="bodySmallRegular" color="regular">
                  {t('cart.subscription')}
                </Txt>
                <Txt variant="bodySmallRegular" color="regular">
                  {t('cart.pricePerMonth', { price: oldPrice ?? price })}
                </Txt>
              </div>
              {hasPromotions && (
                <div className={styles['promotions']}>
                  {promotions.map((promotion, i) => (
                    <div key={i} className={styles['promotion']}>
                      <Txt
                        variant="bodySmallBold"
                        color="success"
                        className={styles['promotion-name']}
                      >
                        <span>{promotion.name}</span>
                        <Icon icon={Offer} size={16} />
                      </Txt>
                      <Txt
                        variant="bodySmallBold"
                        color="success"
                        className={styles['promotion-price']}
                      >
                        {t('cart.pricePerMonth', {
                          price: Math.abs(promotion.amount),
                          context: 'discount',
                        })}
                      </Txt>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={styles['total']}>
              <Txt variant="heading6" color="regular">
                {t('cart.total')}
              </Txt>
              <Txt
                variant="heading6"
                color="regular"
                style={{ textAlign: 'right' }}
              >
                {price === 0
                  ? t('cart.free')
                  : t('cart.pricePerMonth', { price })}
                <div>
                  {hasPromotions &&
                    oldPrice !== undefined &&
                    totalPromotionDuration !== undefined && (
                      <Txt component="p" variant="smallItalic" color="subtle">
                        {t('cart.priceAfterDiscount', {
                          price: oldPrice,
                          duration: totalPromotionDuration,
                        })}
                      </Txt>
                    )}
                  <Txt component="p" variant="smallRegular" color="regular">
                    {t('cart.taxes')}
                  </Txt>
                </div>
              </Txt>
            </div>
          </div>
          <TermsAndConditions content={termsAndConditionsContent} />
          <Button
            width="100%"
            onClick={handleClick}
            disabled={isSubmitDisabled}
          >
            {submitButtonLabel}
          </Button>
          <Txt component="p" variant="tinyRegular" color="subtle">
            {t('cart.taxesDisclaimer')}
          </Txt>
        </div>
      </Surface>
    </div>
  );
};

type AddonProps = {
  name: string;
  kind: string;
};

const Addon: React.FC<AddonProps> = ({ name, kind }) => {
  return (
    <div className={styles['addon']}>
      <AddonIcon kind={kind} className={styles['icon']} />
      <Txt variant="paragraphBold" color="regular">
        {name}
      </Txt>
    </div>
  );
};
