import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { ShopAddons } from '@mfe/features/add-ons';
import { selectConfig } from '@mfe/shared/redux/config';

const ShopAddonsPage: React.FC = () => {
  const history = useHistory();
  const { showAddonsShop } = useSelector(selectConfig);

  const navigateToPreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!showAddonsShop) {
      history.replace('/');
    }
  }, [history, showAddonsShop]);

  if (!showAddonsShop) {
    return <></>;
  }

  return <ShopAddons navigateToPreviousPage={navigateToPreviousPage} />;
};

export default ShopAddonsPage;
