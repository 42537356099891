import { Spinner, StandardModal, Txt } from '@vst/beam';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type LoadingE911ModalProps = {
  isOpen: boolean;
};
export const LoadingE911Modal = ({ isOpen }: LoadingE911ModalProps) => {
  const { t } = useTranslation('ShopAddons');
  return (
    <div data-cy={'loading-e911-modal'}>
      <StandardModal
        isOpen={isOpen}
        header={undefined}
        actions={{
          primary: {
            label: '',
            style: { display: 'none' },
          },
          secondary: undefined,
        }}
        surfaceProps={{ className: styles['modal-wrapper'] }}
      >
        <div>
          <Spinner
            description={
              <Txt color="subtle">{t('voice.emergencyAddress.loading')}</Txt>
            }
          />
        </div>
      </StandardModal>
    </div>
  );
};
