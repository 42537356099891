import { Badge, Button, Icon, Txt } from '@vst/beam';
import styles from './index.module.scss';
import { AddonsGridItemType } from './types';
import { AddonIcon } from '@mfe/shared/components';
import { Offer } from '@vst/beam-icons/icons';

export const AddonsGridItem: React.FC<AddonsGridItemType> = (props) => {
  const {
    kind,
    title,
    badgeText,
    price,
    oldPrice,
    description,
    button,
    promotions,
  } = props;

  return (
    <div className={styles['gridItem']}>
      <AddonGridItemImage kind={kind} />
      <div className={styles['gridItem__header']}>
        <AddonTitle title={title} badgeText={badgeText} />
        <AddonDescription description={description} />
      </div>
      <div className={styles['gridItem__pricesAndPromotions']}>
        <div className={styles['gridItem__prices']}>
          {oldPrice !== undefined && (
            <AddonPrice price={oldPrice} isOldPrice={true} />
          )}
          {price !== undefined && <AddonPrice price={price} />}
        </div>
        {!!promotions && !!promotions.length && (
          <div className={styles['gridItem__promotions']}>
            {promotions.map((promotion, i) => {
              return (
                <div key={i} className={styles['gridItem__promotion']}>
                  <Icon icon={Offer} size={16} />
                  <Txt variant="smallRegular">{promotion.name}</Txt>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <AddonButton button={button} />
    </div>
  );
};

type AddonGridItemImageType = Pick<AddonsGridItemType, 'kind'>;

const AddonGridItemImage = (props: AddonGridItemImageType) => {
  return <AddonIcon kind={props.kind} className={styles['gridItem__image']} />;
};

type AddonTitleProps = Pick<AddonsGridItemType, 'title' | 'badgeText'>;

const AddonTitle = (props: AddonTitleProps) => {
  const { title, badgeText } = props;

  return (
    <div className={styles['gridItem__title']}>
      <Txt variant="heading2" className={styles['gridItem__title-text']}>
        {title}
      </Txt>
      {badgeText && (
        <Badge
          size="small"
          showIcon={false}
          emphasis="light"
          state="infoPrimary"
          label={badgeText}
        />
      )}
    </div>
  );
};

type AddonPriceProps = Required<Pick<AddonsGridItemType, 'price'>> & {
  isOldPrice?: boolean;
};

const AddonPrice = (props: AddonPriceProps) => {
  const { price, isOldPrice } = props;
  const splitPrice = price.toString().split('.');
  const integer = splitPrice[0];
  const decimals = (splitPrice[1] ?? '0').padEnd(2, '0');

  const priceVariant = !isOldPrice ? 'heading3' : 'subHeading2';
  const periodVariant = !isOldPrice ? 'bodySmallBold' : 'bodySmallRegular';

  let wrapperClassname = styles['gridItem__price'];

  if (isOldPrice) {
    wrapperClassname = `${styles['gridItem__price']} ${styles['gridItem__price--old']}`;
  }

  return (
    <div className={wrapperClassname}>
      {price === 0 ? (
        <div className={styles['gridItem__price-wrap']}>
          <Txt variant={priceVariant}>Free</Txt>
        </div>
      ) : (
        <>
          <div className={styles['gridItem__price-wrap']}>
            <Txt variant={priceVariant}>
              ${integer}
              <Txt
                component="span"
                variant="headerCapsLarge"
                className={styles['gridItem__price-decimals']}
              >
                {decimals}
              </Txt>
            </Txt>
          </div>
          <Txt variant={periodVariant}>/mo</Txt>
        </>
      )}
    </div>
  );
};

type AddonDescriptionType = Pick<AddonsGridItemType, 'description'>;

const AddonDescription = (props: AddonDescriptionType) => {
  return (
    <div className={styles['gridItem__description']}>
      <Txt variant="bodyLargeRegular">{props.description}</Txt>
    </div>
  );
};

type AddonButtonType = Pick<AddonsGridItemType, 'button'>;

const AddonButton = (props: AddonButtonType) => {
  const { button } = props;
  const { text, disabled, onClick } = button;
  return (
    <div className={styles['gridItem__action']}>
      <Button
        variant="secondary"
        disabled={disabled}
        id="terms"
        fluid={true}
        onClick={onClick}
      >
        {text}
      </Button>
    </div>
  );
};
