import { ProductType } from '@mfe/shared/schema-types';
import { AddonPromotion } from './types';

export const sortProductTypesByDisplayOrder = (
  a: ProductType,
  b: ProductType
) => {
  const aOrder = a.marketing_copy.ui_behaviors.characteristics?.find(
    (characteristic) => characteristic.name === 'DISPLAY_ORDER'
  )?.value;
  const bOrder = b.marketing_copy.ui_behaviors.characteristics?.find(
    (characteristic) => characteristic.name === 'DISPLAY_ORDER'
  )?.value;

  if (aOrder && bOrder) {
    if (aOrder === bOrder) {
      return 0;
    }

    return aOrder > bOrder ? 1 : -1;
  }

  if (aOrder) {
    return 1;
  }

  if (bOrder) {
    return -1;
  }

  return 0;
};

export const getPriceAndDiscount = (addon: ProductType) => {
  const price = addon.prices?.[0].amount?.value ?? 0;
  const promotions: Array<AddonPromotion> = [];
  let amountTotal = 0;
  let totalPromotionDuration;

  if (price === undefined) {
    return {};
  }

  if (addon.discounts?.total_discounts?.amount?.value) {
    amountTotal += addon.discounts.total_discounts.amount.value;
  }

  if (addon.discounts?.total_discounts?.duration) {
    totalPromotionDuration = addon.discounts?.total_discounts?.duration;
  }

  if (
    addon.discounts?.itemized_discounts?.length &&
    addon.discounts?.itemized_discounts?.length > 0
  ) {
    addon.discounts?.itemized_discounts.forEach((discount) => {
      if (discount?.name && discount?.amount?.value) {
        promotions.push({
          name: discount?.name,
          amount: discount?.amount?.value,
        });
      }
    });
  }

  if (amountTotal !== 0) {
    return {
      oldPrice: price,
      price: price + amountTotal,
      promotions,
      totalPromotionDuration,
    };
  }

  return {
    price: price,
  };
};
